/* @import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap'); */
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");

/* this should be handled by the baseline css component */
/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  /* border: 0; */
  /* -webkit-text-fill-color: rgba(0, 0, 0, 0.87); */
  /* -webkit-box-shadow: 0 0 0px 1000px #000 inset; */
  /* transition: background-color 5000s ease-in-out 0s; */
}

code {
  font-family: "Roboto";
}

.background-shadow {
  height: 100%;
}

body,
html,
#root {
  margin: 0;
  padding: 0;
  font-family: "Roboto";
}

* {
  box-sizing: border-box;
}

#searchBarInput::placeholder {
  color: #FFFFFF;
}